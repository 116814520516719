<template>
  <div class="app-all">
    <header :style="{background: navIndex == 0 ? '#090A0C' : '#fff'}">
      <img :src="navIndex == 0 ? DzjfAQ : A843wE" alt="官网logo"/>
    </header>
    <!-- <div class="nav-ontent"> -->
      <nav :class="{'navBorderColor': navIndex == 0}">
        <div :class="{'naVcolor': navIndex == 0}" @click="navClick(0, '/home')"><span>官网首页</span></div>
        <div class="nav-hover" :class="{'naVcolor': navIndex == 1}">
          <span class="nav-hover-parent">德方产品</span>
          <div class="nav-hover-div">
            <div @click="navClick(1, '/medical')" class="pointer"><span>医疗系统</span></div>
            <div>
              <span>居家健康</span>
              <div class="nav-hover-div-child">
                <div @click="navClick(1, '/medical', {index: 1})">智能马桶</div>
                <div @click="navClick(1, '/medical', {index: 2})">智能腕表</div>
                <div @click="navClick(1, '/medical', {index: 3})">智能眼镜</div>
              </div>
            </div>
          </div>
        </div>
        <div :class="{'naVcolor': navIndex == 2}" @click="navClick(2, '/media')"><span>媒体报道</span></div>
        <div class="nav-hover" :class="{'naVcolor': navIndex == 3}">
          <span class="nav-hover-parent">关于我们</span>
          <div class="nav-hover-div nav-hover-div2">
            <div @click="navClick(3, '/aboutUs', {index: 0})"><span>企业愿景</span></div>
            <div @click="navClick(3, '/aboutUs', {index: 1})"><span>经营理念</span></div>
            <div @click="navClick(3, '/aboutUs', {index: 2})"><span>团队精英</span></div>
            <div @click="navClick(3, '/aboutUs', {index: 3})"><span>加入我们</span></div>
          </div>
        </div>
        <div :class="{'naVcolor': navIndex == 4}" @click="navClick(4, '/bidding')"><span>招标公告</span></div>
        <div :class="{'naVcolor': navIndex == 5}" @click="navClick(5, '/latest')"><span>最新消息</span></div>
      </nav>
  <!-- </div> -->
    <main>
      <router-view></router-view>
    </main>
    <footer>
      <div class="footer-div1" :style="{background: `url(${A84pAI}) center no-repeat`}" v-if="navIndex != 5">
        <div class="footer-left">
          <div class="footer-nav">
            <div @click="navClick(0, '/home')">官网首页</div>
            <div @click="navClick(1, '/medical')">德方介绍</div>
            <div @click="navClick(2, '/media')">媒体报道</div>
            <div @click="navClick(3, '/aboutUs', {index: 0})">关于我们</div>
            <div @click="navClick(4, '/bidding')">招标公告</div>
          </div>
          <div class="footer-div1-info">
            <div>重庆德方信息技术有限公司</div>
            <div>地点：重庆市大渡口区春晖路街道翠柏路101号天安数码城1幢A座4-9</div>
            <div>电话：023-68531873</div>
            <div>邮箱：cqdefangxinxi@163.com</div>
            <div>网址：www.cqdefang.com</div>
          </div>
        </div>
        <div class="footer-map" id="baiduMap">
          <baidu-map class="bm-view" ak="okfsjkR1qPmkAEMHHb6XGosc1xRWbDPG" :scroll-wheel-zoom="true" :center="center" :zoom="zoom">
            <bm-marker :position="center" @click="openInfo"></bm-marker>
            <bm-info-window :position="center" :width="300" :show="infoShow" @close="infoClose" @open="openInfo" >
              <div class="infoMaptit">重庆市大渡口区春晖路街道翠柏路101号天安数码城1幢A座4-9</div>
              <div class="infoMapname">重庆德方信息技术有限公司</div>
            </bm-info-window>
          </baidu-map>
        </div>
      </div>
      <div class="footer-div2">
        <div class="footer-record" :style="{alignItems: (navIndex != 0 && navIndex != 5) ? 'flex-start' : 'center'}">
          <div>©2022 重庆德方信息技术有限公司 版权所有</div>
          <div>技术支持：<a hidefocus='ture' rel="nofllow" href="https://management.cqdefang.com/" target="_blank">后台管理登录</a> | <a hidefocus='ture' rel="nofllow" href="https://beian.miit.gov.cn/" target="_blank">渝ICP备19003220号-1</a></div>
          <div class="beian" v-if="navIndex == 0 || navIndex == 5">
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010402000500" target="_blank" hidefocus='ture' rel="nofllow">
              <img :src="public_security_icon" alt="备案图标" />
              <span>渝公网安备 50010402000500号</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
    <div @click="handlesScroll" class="Topping" title="回到顶部" :class="{'ToppingHide': isTopping}" :style="{display: ToppingHide ? 'none' : 'block'}">
      <img :src="Topping" alt="回到顶部" />
    </div>
  </div>
</template>
<script>
import { DzjfAQ, A84pAI, public_security_icon, Topping, A843wE } from '@/utils/imgUrl'
import debounce from 'lodash/debounce';
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from "vue-baidu-map/components/overlays/Marker";
import BmInfoWindow from "vue-baidu-map/components/overlays/InfoWindow";
export default {
  name: 'index',
  components: {
    BaiduMap, BmMarker, BmInfoWindow
  },
  data() {
    return {
      navIndex: localStorage.getItem('navIndex') ? localStorage.getItem('navIndex') : 0,
      isTopping: false,
      ToppingHide: true,
      center: {lng: 106.482207, lat: 29.471761},
      zoom: 17,
      infoShow: true
    };
  },
  computed:{
    DzjfAQ: () => DzjfAQ,
    A84pAI: () => A84pAI,
    public_security_icon: () => public_security_icon,
    Topping: () => Topping,
    A843wE: () => A843wE,
  },
  watch:{
    $route(to,from){
      // console.log(from);//从哪来
      // console.log(to);//到哪去
      if(to.path == '/home'){
        this.localNavIndex(0)
      }
      if(to.path == '/medical'){
        this.localNavIndex(1)
      }
      if(to.path == '/media'){
        this.localNavIndex(2)
      }
      if(to.path == '/aboutUs'){
        this.localNavIndex(3)
      }
      if(to.path == '/bidding'){
        this.localNavIndex(4)
      }
      if(to.path == '/latest'){
        this.localNavIndex(5)
      }
    }
  },
  mounted(){
    const debounced = debounce(this.pageScroll, 200)
    window.addEventListener('scroll', debounced)
  },
  methods:{
    openInfo(){
      this.infoShow = true
    },
    infoClose(){
      this.infoShow = false
    },
    navClick(index, path, query){
      if(path){
        this.localNavIndex(index)
      }
      this.$router.push({path: path,query: query})
    },
    localNavIndex(index){
      this.navIndex = index
      localStorage.setItem('navIndex', index);
    },
    pageScroll(){
      let scroll = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
      if(scroll == 0){
        this.isTopping = false
        setTimeout(()=>{
          this.ToppingHide = true
        },500)
      }
      if(scroll > 0){
        this.ToppingHide = false
        setTimeout(()=>{
          this.isTopping = true
        },0)
      }
    },
    handlesScroll(){
      window.scroll(0, 0)
    }
  }
}
</script>
<style lang="scss" scoped>
header{
  width: 100%;
  padding: 30px 0;
  background: #090A0C;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  img{
    width: 1300px;
  }
}
.bm-view {
  width: 100%;
  height: 205px;
}
.infoMaptit{
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.infoMapname{
  font-weight: initial;
  font-size: 14px;
  color: #333;
}
.app-all{
  min-width: 1300px;
}
nav{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
  color: #999999;
  font-size: 16px;
  // span:hover{
  //   color: #0ab5a3;
  // }
}
nav > div{
  position: relative;
  cursor: pointer;
  margin-right: 50px;
  height: 40px;
  border-bottom: 2px solid transparent;
}
nav > div:last-child{
  margin-right: 0;
}
nav > div:hover > span{
  color: #0ab5a3;
}
// nav > div:hover > .nav-hover-parent{
//   color: #0ab5a3;
// }
nav > div:hover > .nav-hover-parent + .nav-hover-div{
  display: block;
}
.nav-hover{
  cursor: default;
}
.nav-hover-div{
  display: none;
  position: absolute;
  left: -13px;
  top: 46px;
  width: 90px;
  background: #fff;
  z-index: 1;
  div{
    font-size: 14px;
    padding: 10px;
  }
}

.nav-hover-div::before{
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  position: absolute;
  left: 40px;
  top: -5px;
}
.nav-hover-div > div > span{
  width: 100px;
  display: block;
  margin-left: 7px;
}
.nav-hover-div2{
  text-align: center;
  div:hover{
    cursor: pointer;
  }
  span{
    display: inline !important;
    margin-left: 0 !important;
  }
}
.nav-hover-div > div:hover > span{
    color: #0ab5a3;
}
.nav-hover-div > div:hover > span + .nav-hover-div-child{
  display: block;
}
.nav-hover-div-child{
  display: none;
  position: absolute;
  right: -100px;
  top: 40px;
  width: 90px;
  background: #fff;
  z-index: 1;
  text-align: center;
  padding: 0 !important;
  div:hover{
    cursor: pointer;
    color: #0ab5a3;
  }
}
.nav-hover-div-child::before{
  content: '';
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #fff;
  position: absolute;
  left: -6px;
  top: 10px;
}
.naVcolor{
  border-bottom: 2px solid #0ab5a3;
}
nav > div:hover{
  border-bottom: 2px solid #0ab5a3;
}
.navBorderColor > div:hover{
  border-bottom: 2px solid transparent;
}
.naVcolor > span{
  color: #0ab5a3;
}
footer{
  background: #202126;
}
.footer-div1{
  margin: auto;
  position: relative;
  width: 1300px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #a9a9a9;
  height: 300px;
}
.footer-left{
  position: absolute;
  left: 2px;
  top: 50px;
}
.footer-nav{
  display: flex;
  div{
    cursor: pointer;
    margin-right: 32px;
  }
}
.footer-div1-info{
  margin-top: 40px;
  div{
    padding-bottom: 5px;
  }
}
.footer-map{
  position: absolute;
  top: 50px;
  right: 200px;
  width: 500px;
  height: 205px;
}
.footer-div2{
  border-top: 1px solid #0ab5a3;
  height: 100px;
}
.footer-record{
  margin: auto;
  width: 1300px;
  height: 100%;
  color: #a9a9a9;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div{
    margin-bottom: 5px;
    img{
      vertical-align: middle;
    }
  }
  a{
    text-decoration: none;
    color: #a9a9a9;
    
  }
  a:hover{
    color: #0ab5a3;
  }
}
.beian{
  a:hover{
    color: #fff;
  }
}
.Topping{
  cursor: pointer;
  position: fixed;
  width: 45px;
  height: 50px;
  right: 57px;
  bottom: 50px;
  overflow: hidden;
  animation: ToppingNone 1s 0s ;
  img{
    margin-top: 50px;
    transition: margin-top 0.5s linear;
  }
}
.ToppingHide{
  img{
    margin-top: 0px;
    transition: margin-top 0.5s linear;
  }
}
</style>